import services from '@superpagos/mf-services';
import {
	FETCH_CITIES,
	FETCH_STATES,
	GET_PAY_POINTS
} from '../types/actions.type';
import { SET_STATES } from '../types/mutations.type';

const state = {
	states: []
};

const getters = {
	states(state) {
		return state.states;
	}
};

const mutations = {
	[SET_STATES](context, states) {
		context.states = states;
	}
};

const actions = {
	[FETCH_CITIES](context, stateId) {
		return services.ApiServiceRefacilOld.get('/location/cities', stateId);
	},

	[FETCH_STATES](context, force) {
		var states = context.getters.states;
		if (states.length > 0 && !force) return Promise.resolve(states);

		return services.ApiServiceRefacilOld.get('/location/states').then(
			states => {
				context.commit(SET_STATES, states);
				return Promise.resolve(states);
			}
		);
	},
	[GET_PAY_POINTS](context, data) {
		return services.ApiService.post('/api/v1/places/near', data);
	}
};

export default {
	state,
	getters,
	mutations,
	actions
};
