import services from '@superpagos/mf-services';
import {
	GET_REQUEST_REVERSE_BALANCE,
	UPDATE_REQUEST_REVERSE_BALANCE,
	VERIFY_ZONE_RESTRICTION
} from '../../types/actions.type';

const actions = {
	async [GET_REQUEST_REVERSE_BALANCE]({ state }, { page, limit, filters }) {
		return await services.ApiServiceAdmin.post(
			'/admin/customers/reverseBalance/getIncomingRequest',
			{ page, limit, filters }
		);
	},
	async [UPDATE_REQUEST_REVERSE_BALANCE]({ state }, { id, status }) {
		return await services.ApiServiceAdmin.post(
			'/admin/customers/reverseBalance/updateStatus',
			{ id, status }
		);
	},
	async [VERIFY_ZONE_RESTRICTION](
		{ state },
		{ latitude, longitude, productId, categoryId }
	) {
		return await services.ApiServiceAdmin.post(
			'/admin/products/verifyZoneRestriction',
			{ latitude, longitude, productId, categoryId }
		);
	},

	async setLatLog({ commit }, { latitude, longitude }) {
		commit('setCoords', { latitude, longitude });
	},
};

export default actions;
